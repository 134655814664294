
import Field from "./controls.filter.Field.js";

export default class FieldCheckbox extends Field {
    constructor(options) {
        super(options);
    }

    loadStorage() {
        super.loadStorage();
        if (this.storage.value == null) {
            this.storage.value = this.options.default;
        }
    }

    getFilterString() {
        if (this.storage.value) {
            return this.checkedClause;
        } else {
            return this.uncheckedClause;
        }
    }

    getFilterItems() {
        if (this.storage.value) {
            if (this.checkedItem) {
                return [ this.checkedItem ];
            }
        } else {
            if (this.uncheckedItem) {
                return [ this.uncheckedItem ];
            }
        }
        return [];
    }

    getFilterItemsWhereClause() {
        if (this.storage.value && this.checkedClause) {
            return this.checkedClause;
        } else if (!this.storage.value && this.uncheckedClause) {
            return this.uncheckedClause;
        }
    }

    getPills() {
        if (this.storage.value) {
            return [{
                text: this.getText(),
                click: () => {
                    this.filter.showFieldSheet = true;
                    this.filter.selectField(null);
                },
                close: () => {
                    this.storage.value = !this.storage.value;
                    this.filter.update();
                },
            }];
        }
        return [];
    }

    getFilterText() {
        return "";
    }

    getText() {
        return this.name;
    }

    isChecked() {
        return this.storage.value;
    }

    setChecked(value) {
        if (value && this.group) {
            for (let field of this.filter.fields) {
                if (field.type === "Checkbox" && field !== this && field.isChecked()) {
                    field.setChecked(false);
                }
            }
        }
        this.storage.value = value;
    }

    toggle() {
        this.setChecked(!this.isChecked());
        this.filter.update();
    }
}
